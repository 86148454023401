#purchases {
  .order-panel {
    border-radius: 6px;
    background-color: #ffffff;
    border: solid 1px rgba(43, 44, 60, 0.14);
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;

    .table-bordered>tbody>tr>td,
    .table-bordered>tfoot>tr>td {
      border-bottom: 0;
    }
  }

  .order-panel-heading {
    background-color: #ffffff;
    padding: 25px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    outline: 0 !important;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  .order-panel>.table:last-child,
  .order-panel>.table-responsive:last-child>.table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .order-panel>.table-responsive {
    border: 0;
    margin-bottom: 0;
  }


  .table-bordered>tbody>tr>td,
  .table-bordered>tfoot>tr>td {
    border-bottom: 0;
  }

  .order-panel>.table-bordered>tbody>tr:first-child>td,
  .order-panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
  .order-panel>.table-bordered>thead>tr:first-child>th,
  .order-panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
  .order-panel>.table-bordered>tbody>tr:first-child>th,
  .order-panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
    border-bottom: 0;
  }

  .order-panel>.table-bordered>tbody>tr>td:last-child,
  .order-panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
  .order-panel>.table-bordered>tfoot>tr>td:last-child,
  .order-panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
  }

  .order-panel>.table-bordered>tbody>tr>td:first-child,
  .order-panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
  .order-panel>.table-bordered>tfoot>tr>td:first-child,
  .order-panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
  }

  .text-style {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #555878;

    &.date {
      font-size: 14px;
      font-weight: 400;
      color: #a0a3b8;
      margin-left: 15px;
    }
  }

  .order-item {
    padding-right: 7px;
    padding-left: 7spx;

    .order-item-title {
      line-height: normal;
    }

    .order-item-text {

      margin-left: 87px;
    }

    .order-item-cover {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-left: 87px;

      .square-image {
        cursor: pointer;
        position: relative;
        width: 100%;
        overflow: hidden;
        background: white;
        background-size: cover;
        background-position: 50% 0%;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);

        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        .square {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          color: white;

          .loader {
            width: 25% !important;
            height: 25% !important;
            margin: auto;
            margin-top: 37.5%;
          }

          & div {
            display: table;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .expand {
    cursor: pointer;
    height: 32px;
  }

  .expand-icon {
    margin-top: 2px;
    margin-left: 20px;
    margin-right: 20px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }

  .expand-icon-minus {
    margin-top: 4px;
  }
}

/*
 * Component: Button
 * -----------------
 */

.btn {
  .border-radius(@btn-border-radius);
  .box-shadow(@btn-boxshadow);
  border: 1px solid transparent;

  &.uppercase {
    text-transform: uppercase
  }

  // Flat buttons
  &.btn-flat {
    .border-radius(0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-width: 1px;
  }

  // Active state
  &:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  &:focus {
    outline: none;
  }

  // input file btn
  &.btn-file {
    position: relative;
    overflow: hidden;
    > input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      .opacity(0);
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}

//Button color variations
.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  &:hover,
  &:active,
  &.hover {
    background-color: darken(#f4f4f4, 5%);
  }
}

.btn-primary {
  background-color: @light-blue;
  border-color: darken(@light-blue, 5%);
  &:hover, &:active, &.hover {
    background-color: darken(@light-blue, 5%);
  }
}

.btn-success {
  background-color: @green;
  border-color: darken(@green, 5%);
  &:hover, &:active, &.hover {
    background-color: darken(@green, 5%);
  }
}

.btn-info {
  background-color: @aqua;
  border-color: darken(@aqua, 5%);
  &:hover, &:active, &.hover {
    background-color: darken(@aqua, 5%);
  }
}

.btn-danger {
  background-color: @red;
  border-color: darken(@red, 5%);
  &:hover, &:active, &.hover {
    background-color: darken(@red, 5%);
  }
}

.btn-warning {
  background-color: @yellow;
  border-color: darken(@yellow, 5%);
  &:hover, &:active, &.hover {
    background-color: darken(@yellow, 5%);
  }
}

.btn-outline {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  &:hover,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, .7);
    border-color: rgba(255, 255, 255, .7);
  }
}

.btn-link {
  .box-shadow(none);
}

//General .btn with bg class
.btn[class*='bg-']:hover {
  .box-shadow(inset 0 0 100px rgba(0, 0, 0, 0.2));
}

// Application buttons
.btn-app {
  .border-radius(3px);
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
  //Icons within the btn
  > .fa, > .glyphicon, > .ion {
    font-size: 20px;
    display: block;
  }

  &:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa;
  }

  &:active, &:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  //The badge
  > .badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400;
  }
}

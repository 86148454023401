/*
 * Page: Mailbox
 * -------------
 */
.mailbox-messages {
  > .table {
    margin: 0;
  }
}

.mailbox-controls {
  padding: 5px;
  &.with-border {
    border-bottom: 1px solid @box-border-color;
  }
}

.mailbox-read-info {
  border-bottom: 1px solid @box-border-color;
  padding: 10px;
  h3 {
    font-size: 20px;
    margin: 0;
  }
  h5 {
    margin: 0;
    padding: 5px 0 0 0;
  }
}

.mailbox-read-time {
  color: #999;
  font-size: 13px;
}

.mailbox-read-message {
  padding: 10px;
}

.mailbox-attachments {
  &:extend(.list-unstyled);
  li {
    float: left;
    width: 200px;
    border: 1px solid #eee;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.mailbox-attachment-name {
  font-weight: bold;
  color: #666;
}

.mailbox-attachment-icon,
.mailbox-attachment-info,
.mailbox-attachment-size {
  display: block;
}

.mailbox-attachment-info {
  padding: 10px;
  background: #f4f4f4;
}

.mailbox-attachment-size {
  color: #999;
  font-size: 12px;
}

.mailbox-attachment-icon {
  text-align: center;
  font-size: 65px;
  color: #666;
  padding: 20px 10px;
  &.has-img {
    padding: 0;
    > img {
      max-width: 100%;
      height: auto;
    }
  }
}

.mailbox-attachment-close {
  &:extend(.close);
}

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  less dependencies when defined in the bower.json of your dependencies
 */
// bower:less
// endbower

/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap/less/variables.less
 */
@navbar-inverse-link-color: #5AADBB;
@icon-font-path: '../../bootstrap/fonts/';

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your less files automatically
 */
// injector
// endinjector

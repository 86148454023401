.edit-event-page {
  .watermarkTransparency{
    margin: 1em 0em 0.2em 0em;
  }
  label span {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .title {
    margin-bottom: 18px;
    margin-left: 12px;
  }
  .upload-placeholder {
    margin-left: 14px;
    max-width: 230px;
    box-shadow: none !important;
    margin-bottom: 32px;
    cursor: pointer;

    .upload-placeholder__content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image {
        max-width: 60px;
        margin-bottom: 8px;
      }

      .text {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
      }
    }
  }
  .event-form-group__input.password {
    -webkit-text-security: disc;
  }
  label {
    font-weight: 500;
    
    &.option-title {
      font-weight: bold;
    }
  }
  .validation {
    background: #FFC199;
	  border-left: 9px solid #FF6600;
	  color: #2c3e50;
    margin: 8px 0 0;
    padding-left: 8px;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    letter-spacing: -0.2px;
    font-size: 14px;
    font-weight: 500;
    color: #4B4B4B;
    line-height: 20px;
    padding: 0;
  }
  input[type="radio"] + label span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -1px 8px 0 0;
    vertical-align: -5px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
  input[type="radio"] + label span {
    background-color: #fff;
    border: 2px solid #2196F3;
  }
  input[type="radio"]:checked + label span {
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #2196F3;
    background: #2196F3;
  }
  .mode-radio {
    margin-bottom: 0px;
    
    &.watermark-position-options {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    input[type="radio"] {
      margin: 4px 0 0;
    }

    input[disabled] + label span {
      border-color: #cccccc;
      cursor: not-allowed;
    }

    label {
      margin-right: 5px;

      @media @mobile {
        margin-bottom: 8px;
      }
    }
  }
  .tag {
    display: inline-block;
    border: 1px solid;
    border-radius: 99px;
    padding: 2px 8px;
    padding-right: 2px;
    margin-right: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    button {
      background: transparent;
      border: none;
    }
  }
}
.modal-dialog.huge {
  max-width: 700px;
  width: fit-content;
}
.crop-image-popup {
  .modal-backdrop {
    opacity: 0.8;
  }

  .modal-dialog {
    max-width: 480px;
    margin-top: 60px;
    .header-hidden {
      height: 28px;
      .close {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        color: white;
        opacity: 0.5;
        text-align: right;
      }
    }
  }
  .button-primary {
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 24px;
    display: block;
    min-width: 200px;
  }
}

#upload-info {
  margin: 24px 0;

  .tags-section {
    margin-top: 8px;

    .validation {
      margin-top: 10px;
      margin-bottom: 0px;
    }

    .tag-title {
      font-family: Montserrat, sans-serif;
      color: #7d7d7d;
      transition: 350ms color ease-in-out;
      font-size: 16px;
    }

    .button-section {
      margin-top: 12px;

      button {
        margin-right: 8px;
      }
    }

    .tag {
      display: inline-block;
      border: 1px solid #4B4B4B;
      border-radius: 99px;
      padding: 2px 8px;
      padding-right: 2px;
      margin-right: 8px;

      button {
        background: transparent;
        border: none;
        color: #4B4B4B;
      }
    }

    .tags-list {
      margin-top: 10px;

      label {
        margin-right: 10px;
        .tag-title();
        font-weight: 400;
      }
    }
  }

  .tag-selector {
    .btn-primary {
      min-height: 0;
      font-size: 13px;
      border-radius: 3px;
    }
  }

  .expand-icon {
    margin-top: 2px;
    margin-left: 10px;
    margin-right: 10px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .expand-icon-minus {
    margin-top: 4px;
  }

  .tags-list {
    padding: 0;

    .expand {
      cursor: pointer;
      height: 32px;
    }
  }

  .ui-select-bootstrap .ui-select-choices-row>a {
    padding: 3px 20px;
    border: none;
  }

  .ui-select-bootstrap .ui-select-choices-row.active>a {
    background-color: #428bca;
  }

  .ui-select-bootstrap .ui-select-choices-row>a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
  }

  .add-tag-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background: #F6F6F6;
    margin-bottom: 16px;

    label {
      margin-bottom: 8px;
    }
  }
}

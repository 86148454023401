/*
 * Page: Invoice
 * -------------
 */

.invoice {
  position: relative;
  background: #fff;
  border: 1px solid #f4f4f4;
  padding: 20px;
  margin: 10px 25px;
}

.invoice-title {
  margin-top: 0;
}

//-------------------------
//    Chosen photo page
//-------------------------
#chosenPhoto {
  @font-size-text:        16px;
  @font-size-main:        20px;
  @font-size-title:       27px;
  @accent: #61AEFF;
  @dark: #242424;


  //-------------------------
  //  Chosen photo container
  //-------------------------
  .photo-section {
    padding-right:      380px;
    height:             100%;
    width:              100%;
    display:            table;

    @media @tablet, @mobileLarge, @mobile {
      height:           60%;
      padding-right:    0;
    }

    .photo-container {
      display:          table-cell;
      vertical-align:   middle;

      .photo {
        text-align:     center;

        //----------Photo----------
        img {
          @media @desktop {
            max-width:    80%;
            max-height:   700px;
          }
          @media @laptop {
            max-width:    80%;
          }
          @media @smallLaptop {
            max-width:    71%;
          }
          @media  @tablet {
            max-width:       70%;
            padding-top:     36px;
            padding-bottom:  36px;
            max-height:      500px;
          }

          @media @mobileLarge {
            max-width:      68%;
          }

          @media @mobile {
            max-width:      68%;
          }

          max-height:   600px;
        }

        //-----Navigation buttons-----
        .nav-arrow {
          font-size:      48px;
          color:          white;
          margin:         20px;
          @media @tablet, @mobile, @mobileLarge {
            margin:       0;
          }
          &.disabled {
            opacity:      .3;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }

    }
  }

  //-------------------------
  // Right side action panel
  //-------------------------
  .action-panel-container {
    position:     fixed;
    display:      table;
    width:        380px;
    height:       100%;
    top:          0;
    right:        0;
    background:   white;

    @media @tablet, @mobileLarge, @mobile {
      position:   static;
      bottom:     0;
      top:        initial;
      width:      100%;
      height:     40%;
    }

    //----- Close modal button -----
    .close-button {
      position:   absolute;
      top:        5px;
      right:      10px;

      &:hover {
        text-decoration:  none;
        color:            @accent;
      }
      span {
        color:          @gray;
        line-height:    44px;
        font-size:      54px;
        display:        block;
        &:hover {
          color:        @accent;
        }
      }
    }

    //----- Inner container -----
    .inner-container {
      display:          table-cell;
      vertical-align:   text-top;


      //----- General section style -----
      .action-container {
        @media @tablet, @mobileLarge, @mobile {
          padding-left:     18px;
          padding-right:    18px;
          padding-top:      18px;
          padding-bottom:   18px;
        }
        padding: 36px;
        border-bottom:      1px solid @gray;
      }


      //-----Author section-----
      .action-container.author-container {

        @media @laptop {
          padding: 16px 36px;
        }

        .user-avatar {
          height: 60px;
          width: 60px;
          display:          inline-block;
          border:           1px solid @accent;
          border-radius:    999px;
          vertical-align:   top;
          @media @mobileLarge, @mobile {
            height:         60px;
            width:          60px;
          }
        }

        .user-info {
          display:          inline-block;
          margin-left:      18px;
          max-width:        207px;
          @media @tablet {
            max-width:      500px;
          }
          @media @mobileLarge {
            max-width:      300px;
            padding-top:    2px;
            margin-left:    9px;
          }
          @media @mobile {
            max-width:      190px;
            padding-top:    2px;
            margin-left:    9px;
          }
          .text {
            overflow:       hidden;
            text-overflow:  ellipsis;
            white-space:    nowrap;
          }

          .name {
            color:           @dark;
            font-size:       @font-size-main;
          }

          .email {
            margin: 0;
            color:           @dark;
            font-size:       @font-size-text;
          }

        }
      }


      //-----Event section-----
      .action-container.event-name-container {

        @media @laptop {
          padding: 10px 36px;
        }

        .name {
          color:        @dark;
          font-size:    @font-size-title;

          @media @laptop {
            font-size: 20px;
          } 
        }

        .date {
          color:        @dark;
          font-size:    @font-size-main;
          @media @tablet, @mobileLarge, @mobile, @laptop {
            margin-right: 16px;
            width:        auto;
            display:      inline-block;
            font-size: @font-size-text;
          }
        }

        h4 {
          @media @laptop {
            font-size: 14px;
          }
        }

        .uploaded {
          color:        @gray;
          font-size:    @font-size-text;
          @media @tablet, @mobileLarge, @mobile {
            width:      auto;
            display:    inline-block;
          }
        }
      }


      //-----Add to favorite, share section-----
      .favorite-share-container {
        padding:        0;
        overflow:       auto;

        //----- Favorites loader -----
        .loader {
          display:        inline-block;
          margin-left:    75px;
          margin-right:   75px;
          margin-top:     43px;
        }

        button {
          padding:      0;
          height:       126px;
          width:        50%;
          float:        left;
          background:   white;
          border:       none;
          border-left:  1px solid @gray;
          font-size:    @font-size-text;
          color:        @dark;
          i {
            font-size:  @font-size-title;
            width:      100%;
          }
          @media @tablet, @mobileLarge, @mobile {
            height:     65px;
            i {
              width:            auto;
              vertical-align:   -5px;
              margin-right:     5px;
            }
          }
          &:hover {
            background: @accent;
            color:      white;
          }
          .spinner {
            display: table-cell;
            vertical-align: middle;
            .spinner-image {
              width: 50px;
              height: 50px;
            }
            &:hover {
              background-color: #FFF;
            }
          }
        }
      }


      //-----Download section-----
      .download-container {
        height: 500px;
        overflow-y: scroll;

        @media @laptop {
          padding: 0 36px;
        }

        border: none;

        h3 {
          margin:         0;
          text-align:     center;
          font-size:      @font-size-main;
          color:          @gray;
          @media @tablet, @mobileLarge, @mobile {
            display:        inline-block;
          }
        }

        button {
          width:          220px;
          margin:         0 auto;
          margin-bottom:     20px;
          display:        block;
          @media @tablet, @mobileLarge, @mobile {
            display:        inline-block;
            margin-top:     0;
            margin-left:    32px;
          }
        }
      }
    }
  }
}

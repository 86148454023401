@font-size-text: 16px;
@font-size-main: 20px;
@font-size-title: 27px;
@accent: #61AEFF;
@dark: #242424;

.double-click {
  &:hover {
    cursor: pointer;
  }
}

.bold-text {
  font-weight: 600;
}

.profile-photos-container {
  max-width: 960px;
}


.form-control {
  color: #555;
}

.popup-input {
  margin-bottom: 20px;
}

.modal-backdrop {
  opacity: 0.6;
}

.pagination {
  margin-bottom: 0px;
  margin-top: 10px;
}

.table-bordered>tbody>tr>td {
  vertical-align: middle;
}

.table-bordered>thead>tr>th {
  vertical-align: middle;
}

.box {
  margin-top: 15px;
  margin-bottom: 10px;
}


.ui-notification.success {
  background-color: @accent;
  border-radius: 3px;
  box-shadow: 0px 0px 2px gray;
  padding: 5px;
}

.event-cover {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  height: 180px;
  width: 180px;
  background-size: cover;
}

.no-event-cover {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  height: 180px;
  width: 180px;
  background-size: cover;
  background-position: 50% 0%;
  background-image: url(../../assets/images/no-image.png);
}

.upload-placeholder {
  border: 1px solid;
  max-width: 230px;
  box-shadow: none !important;
  margin-bottom: 32px;
  cursor: pointer;

  .upload-placeholder__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
      max-width: 60px;
      margin-bottom: 8px;
    }

    .text {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
    }
  }
}

.square-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: white;
  background-size: cover;
  background-position: 50% 0%;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  .square {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    //photo loader
    .loader {
      width: 25% !important;
      height: 25% !important;
      margin: auto;
      margin-top: 37.5%;
    }
  }
  .square div {
    display: table;
    width: 100%;
    height: 100%;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-radius: 0;
    background: #ccc;
    padding: 6px;
    opacity: 0.7;
    line-height: 12px;
    text-shadow: none;
    i {
      font-size: 12px;
      margin: 0;
    }
    &:before {
      border: none;
    }
  }
}

.square-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.upload-thumbnail {
  max-width: 230px;
  position: relative;
  border: 1px solid;
  margin-bottom: 8px;

  .picture {
    max-width: 100%;
  }

  .remove-icon {
    position: absolute;
    right: 0;
    background: #f6f6f6;
    border: 1px solid;
    text-decoration: none;
  }
}

.photo-thumbnail {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  img {
    width: 160px;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;

    @media @smallLaptop {
      width: 120px;
      height: 120px;
    }

    &.photo-thumbnail-loading {
      margin: 30px;
      width: 100px;
      height: 100px;
    }
  }

  .face-not-found {
    position: absolute;
    top: 70px;
    left: 25px;
    color: #FF5722;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-shadow: 0px 1px 0px rgba(150, 150, 150, 0.3);
  }
}

.user-avatar {
  border: 1px solid #ccc;
  float: left;
  height: 55px;
  width: 55px;
  background-size: cover;
  border-radius: 55px;

  @media @smallLaptop,
  @laptop {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}

.user-no-avatar {
  border: 1px solid #ccc;
  float: left;
  height: 55px;
  width: 55px;
  border-radius: 55px;
  background-size: cover;
  background-position: 50% 0%;
  background-image: url(../../assets/images/no-user-avatar-2.png);

  @media @smallLaptop,
  @laptop {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}


.user-container {
  .user-avatar {
    height: 70px;
    width: 70px;
    display: inline-block;
    border: 1px solid @accent;
    border-radius: 999px;
    vertical-align: top;

    @media @mobileLarge,
    @mobile {
      height: 60px;
      width: 60px;
    }
  }

  .user-info {
    display: inline-block;
    margin-left: 18px;
    padding-top: 6px;
    max-width: 207px;

    @media @tablet {
      max-width: 500px;
    }

    @media @mobileLarge {
      max-width: 300px;
      padding-top: 2px;
      margin-left: 9px;
    }

    @media @mobile {
      max-width: 190px;
      padding-top: 2px;
      margin-left: 9px;
    }

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      color: @dark;
			font-size: @font-size-main;
			margin-bottom: 4px;
    }

    .email {
      color: @dark;
      font-size: @font-size-text;
    }

  }
}

.background-img {
  height: 55px;
  width: 55px;
  background-size: cover;

  @media @smallLaptop,
  @laptop {
    width: 40px;
    height: 40px;
  }
}

.background-no-img {
  height: 55px;
  width: 55px;
  background-size: cover;
  background-position: 50% 0%;
  background-image: url(../../assets/images/no-image.png);

  @media @smallLaptop,
  @laptop {
    width: 40px;
    height: 40px;
  }
}

.attachment-img {
  width: 110px;
  height: 110px;

  @media @tablet,
  @mobileLarge,
  @mobile {
    width: 145px;
    height: 145px;
  }

  @media @smallLaptop {
    width: 70px;
    height: 70px;
  }

  @media @laptop {
    width: 70px;
    height: 70px;
  }
}

.attachment-img-event-photos {
  width: 200px;
  height: 200px;

  @media @tablet,
  @mobileLarge,
  @mobile {
    width: 100px;
    height: 100px;
  }

  @media @smallLaptop {
    width: 150px;
    height: 150px;
  }

  @media @laptop {
    width: 150px;
    height: 150px;
  }
}

.statistic-item {
  color: white;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

.set-event-owner-container {
  .users-list-container {
    height: 288px;
    overflow-y: scroll;

    .users-list-item {
      &:hover {
        border: 1px solid @list-group-border;
        background-color: #e6e6e6;
      }

      &:focus {
        outline: none;
        background-color: #e6e6e6;
      }

      &:disabled {
        background-color: #e6e6e6;
      }
    }

    .list-group-item {
      position: relative;
      display: block;
      padding: 10px 15px;

      // Place the border on the list items and negative margin up for better styling
      margin-bottom: -1px;
      background-color: @list-group-bg;
      border: 1px solid @list-group-border;

      // Round the first and last items
      &:first-child {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
}

.list-group {
  padding-right: 0px;
  border: 1px solid #e6e6e6;
}

.alert-danger {
  border-radius: 0px;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #ebccd1;
  text-align: center;
}

//  ============= Buttons =============

.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;

  &:focus {
    outline: none;
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
  }

  &:active {
    outline: none;

    &:hover {
      outline: none;
    }
  }
}

.silver {
  color: #7d7d7d;
}

.sort-container {
  float: left;
  margin: 10px;
  padding-top: 2px;
}

.statistics-container {
  position: absolute;
  top: 0px;
  left: 20px;
  padding: 8px;
}

.action-button-container {
  margin-bottom: 20px;
  margin-top: 20px;
}

.action-button {
  margin: 1px;
}

.admin-rights-button {
  margin-left: 50px;
}

.ui-select-search {
  font-size: 16px;
  padding-left: 12px;
  padding-right: 12px;
  background: #fafafa;
  border: 1px solid #DEDEDE;
  border-radius: 0;
  color: #3C3C3C;
  width: 100% !important;
}
.ui-select-search:focus {
  border: 1px solid #DEDEDE !important;
  box-shadow: none !important;
}
.ui-select-choices {
  max-height: 400px !important;
  margin-top: 0px;
}
.ui-select-bootstrap .ui-select-choices-row > a {
  padding: 0px;
  border-top: 1px solid #E6E6E6;
}
.ui-select-choices-content {
  padding: 0;
  border-radius: 0;
  border: none;
}
.ui-select-choices-content .ui-select-choices-row-inner .user-photo {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin-right: 8px;
  background-size: cover;
  background-position: 50% 0%;
  vertical-align: top;
}
@media only screen and (min-width: 319px) and (max-width: 767px) {
  .ui-select-choices-content .ui-select-choices-row-inner .user-photo {
    height: 48px;
    width: 48px;
  }
}
.ui-select-choices-content .ui-select-choices-row-inner .user-info {
  display: inline-block;
}
.ui-select-choices-content .ui-select-choices-row-inner .user-info .user-email {
  margin: 0px;
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 16px;
  color: #4B4B4B;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  letter-spacing: -1px;
}
@media only screen and (min-width: 319px) and (max-width: 767px) {
  .ui-select-choices-content .ui-select-choices-row-inner .user-info .user-email {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.ui-select-choices-content .ui-select-choices-row-inner .event-photo {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin-right: 8px;
  background-size: cover;
  background-position: 50% 0%;
  vertical-align: top;
}
@media only screen and (min-width: 319px) and (max-width: 767px) {
  .ui-select-choices-content .ui-select-choices-row-inner .event-photo {
    height: 48px;
    width: 48px;
  }
}
.ui-select-choices-content .ui-select-choices-row-inner .event-info {
  display: inline-block;
}
.ui-select-choices-content .ui-select-choices-row-inner .event-info .name {
  margin: 0px;
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 16px;
  color: #4B4B4B;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  letter-spacing: -1px;
}
@media only screen and (min-width: 319px) and (max-width: 767px) {
  .ui-select-choices-content .ui-select-choices-row-inner .event-info .name {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.ui-select-choices-content .ui-select-choices-row-inner .event-info .updated,
.ui-select-choices-content .ui-select-choices-row-inner .event-info .event-date {
  font-size: 14px;
  font-weight: 300;
  color: #7D7D7D;
  padding-right: 8px;
}
@media only screen and (min-width: 319px) and (max-width: 767px) {
  .ui-select-choices-content .ui-select-choices-row-inner .event-info .updated,
  .ui-select-choices-content .ui-select-choices-row-inner .event-info .event-date {
    font-size: 11px;
  }
}
.ui-select-choices-content .ui-select-choices-row-inner .event-info .event-date {
  border-left: 1px solid #E6E6E6;
  padding-left: 10px;
}
.ui-select-choices-content .ui-select-choices-row-inner .event-info .not-found {
  padding: 20px 12px;
  margin: 0;
}
.ui-select-choices-content .ui-select-bootstrap .ui-select-choices-row.active > a {
  display: block;
  float: left;
  width: 100%;
}
.ui-select-bootstrap .ui-select-choices-row > a:hover .name {
  color: white;
}
.ui-select-bootstrap .ui-select-choices-row > a:hover .updated,
.ui-select-bootstrap .ui-select-choices-row > a:hover .event-date {
  color: #E6E6E6;
}
.ui-select-bootstrap .ui-select-choices-row:last-child > a {
  padding: 0;
}
.ui-select-bootstrap .ui-select-choices-row.disabled > a,
.ui-select-bootstrap .ui-select-choices-row.active.disabled > a {
  cursor: default;
}

.tags-list {
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px rgba(43, 44, 60, 0.14);
  margin-bottom: 20px;
  padding: 5px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  
  .expand {
    margin: 5px 0;
  }

  .user-tags {
    padding: 0 36px;
    
    .user-tag-row {
      padding: 12px 0px;
      min-height: 50px;
      letter-spacing: -0.2px;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #4B4B4B;
      line-height: 20px;
      border-bottom: 1px solid #C8C8C8;
  
      &:last-child {
        border-bottom: none;
      }
  
      .user-tag-row-item {
        display: flex;
        align-items: center;
        min-height: 50px;
  
        .tag-thumbnail {
          margin-right: 16px;
  
          .photo {
            height: 50px;
            width: 50px;
            background-size: cover;
            border-radius: 99px;
          }
        }
  
        p {
          margin: 0;
        }
      }
  
      .buttons-section {
        display: flex;
        align-items: center;
        min-height: 50px;
  
        button {
          margin-right: 12px;
  
          &::before {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}


@desktop-hd: ~"only screen and (min-width: 1920px)";
@desktop: ~"only screen and (min-width: 1600px) and (max-width: 1920px)";
@laptop: ~"only screen and (min-width: 1440px) and (max-width: 1600px)";
@smallLaptop: ~"only screen and (min-width: 1024px) and (max-width: 1439px)";
@tablet: ~"only screen and (min-width: 768px) and (max-width: 1023px)";
@mobileLarge: ~"only screen and (min-width: 450px) and (max-width: 767px)";
@mobile: ~"only screen and (min-width: 319px) and (max-width: 449px)";
